var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"gallery_page"},[_c('div',{staticClass:"gallery_head"},[_c('div',{style:({ width: _vm.$mq == 'mobile' ? '70%' : '40%' })},[_c('h5',[_vm._v("Gallery")]),_c('h1',{staticClass:"mb24"},[_vm._v(_vm._s(_vm.info.title))]),_c('p',[_vm._v(" "+_vm._s(_vm.info.paragraph)+" ")]),(_vm.info.actionLink)?_c('button',{staticClass:"mt24 black",on:{"click":function($event){return _vm.$bus.redirect(_vm.info.actionLink, false, true)}}},[_vm._v(" "+_vm._s(_vm.info.actionText)+" ")]):_vm._e()]),(_vm.$mq != 'mobile')?_c('div',{staticClass:"gallery_right",staticStyle:{"width":"60%"}},_vm._l((_vm.info.displayPhotos.slice(1, 4)),function(p){return _c('div',{key:p.id,staticClass:"gallery_display",style:({
          background: 'url(' + p.url + ') center / contain no-repeat',
        })})}),0):_vm._e()]),(_vm.info.displayPhotos.length > 0)?_c('div',{staticClass:"wfill",staticStyle:{"height":"50vh"},style:({
      background:
        'url(' + _vm.info.displayPhotos[0].url + ') center / cover no-repeat',
    })}):_vm._e(),_vm._m(0),_c('div',[(_vm.masterEvents.length > 0)?_c('div',{staticClass:"me_chip_parent",staticStyle:{"border-bottom":"1px solid #eee"}},[_c('div',{staticClass:"flex wrap",staticStyle:{"flex-wrap":"true"}},_vm._l((_vm.masterEvents),function(me){return _c('div',{key:me.id,staticClass:"me_chip",class:{ active: _vm.activeMasterEventId == me.id },on:{"click":function($event){_vm.activeMasterEventId != me.id
              ? (_vm.activeMasterEventId = me.id)
              : (_vm.activeMasterEventId = '')}}},[_vm._v(" "+_vm._s(me.name)+" "),(_vm.galleryCounts[me.id])?_c('span',[_vm._v("("+_vm._s(_vm.galleryCounts[me.id])+")")]):_vm._e()])}),0)]):_vm._e()]),_c('div',{staticClass:"nowrap mt12 ml48",staticStyle:{"border":"1px solid #ccc","width":"200px","padding":"0 12px"}},[_c('i',{staticClass:"ml8 search icon"}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search_input",attrs:{"placeholder":"Search","type":"text"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),(!_vm.loading)?_c('div',{staticClass:"gallery_grid"},[_vm._l((_vm.searchedGalleries),function(g){return _c('div',{key:g.id,staticClass:"pointer gallery",style:({
        background: 'url(' + g.photos[0] + ') center / cover no-repeat',
      }),on:{"click":function($event){return _vm.showGalleryModal(g.id)}}},[_c('div',{staticClass:"mtauto p16",staticStyle:{"background":"white","border-top":"1px solid #eee"}},[_c('h6',[_vm._v(_vm._s(g.Title))]),_c('p',{staticClass:"mt4"},[_vm._v(_vm._s(g.photos.length)+" images")])])])}),_c('div',[(_vm.activeGalleryObj)?_c('LightBox',{ref:"lightbox",attrs:{"media":_vm.activeGalleryObj.photos
            .slice(0, _vm.activeGalleryObj.photos.length)
            .map((p, ix) => {
              _vm.console.log(_vm.activeGalleryObj.descriptions[ix]);
              return {
                thumb: p,
                src: p,
                caption:
                  _vm.activeGalleryObj.descriptions[ix] || _vm.activeGalleryObj.Title,
                index: ix,
              };
            }),"controlsHidden":false,"showCaption":true,"onClosed":() => (_vm.activeGallery = null)},scopedSlots:_vm._u([{key:"customCaption",fn:function(slotProps){return [_vm._v(" "+_vm._s(slotProps.currentMedia.caption)),_c('br')]}},{key:"previous",fn:function(){return [_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"15 18 9 12 15 6"}})])]},proxy:true},{key:"next",fn:function(){return [_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24","fill":"none","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('polyline',{attrs:{"points":"9 18 15 12 9 6"}})])]},proxy:true},{key:"close",fn:function(){return [_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"white","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"18","y1":"6","x2":"6","y2":"18"}}),_c('line',{attrs:{"x1":"6","y1":"6","x2":"18","y2":"18"}})])]},proxy:true}],null,false,3206132409)}):_vm._e()],1)],2):_c('div',{staticClass:"my-8 flex wfill"},[_c('h6',{staticClass:"wfill mx-auto italic",staticStyle:{"text-align":"center"}},[_vm._v(" Loading... ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt40"},[_c('h2',{staticClass:"center"},[_vm._v("View Gallery")]),_c('p',{staticClass:"center mt12"},[_vm._v(" Select an event below to browse through the image catalogue ")])])
}]

export { render, staticRenderFns }
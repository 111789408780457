export const galleryKey = {
	"Archumen 11-12 Western Interface": [
		"arch1112west_1runrup.gif",
		"arch1112west_2runrup.gif",
		"arch1112west_winner.gif",
	],
	"Archumen 11-12 Eastern Interface": [
		"arch1112east_1runrup.gif",
		"arch1112east_2runrup.gif",
		"arch1112east_winner.gif",
	],
	"Archumen 11-12 South Interface": [
		"arch1112south.gif",
		"arch1112south_1strunrup.gif",
		"arch1112south_2runrup.gif",
		"arch1112south_winner.gif",
	],
	"Archumen 11-12 North Interface": [
		"new1.jpg",
		"new2.jpg",
		"new3.jpg",
		"new4.jpg",
		"new5.jpg"
	],
	"Archumen 11-12 Grand Finale": [
		"arch1112grandfinale.gif",
		"arch1112grandfinale_1strunrup.gif",
		"arch1112grandfinale_2runrup.gif",
		"arch1112grandfinale_champions.gif",
		"arch1112grandfinale_championstrophy.gif",
	], 
	"Archumen 10-11 East Interface": [
		"arch10-east-1st-run.jpg",
		"arch10-east-winners.jpg",
	],
	"Archumen 10-11 North": [
		"arch10-north-1st.jpg",
		"arch10-north-2nd.jpg",
		"arch10-north-audience.jpg",
		"arch10-north-winners.jpg",
		"arch10-north-winners-all.jpg"
	],
	"Archumen 10-11 Grand Finale": [
		"ar_grand1011_nch.jpg",
		"ar_grand1011_wgf.jpg",
		"ar_grand1011_gf1.jpg",
		"ar_grand1011_gf2.jpg",
		"ar_grand1011_gf3.jpg",
	],
	"Archumen Lahore": [
		"ar_l4.jpg",
		"ar_l5.jpg",
		"ar_l1.jpg",
		"ar_l2.jpg",
		"ar_l3.jpg"
	],
	"Archumen 09-10 Eastern Interface": [
		"arch09east_champions.jpg",
		"arch09east_winners.jpg",
		"arch09east_quizprogress.jpg"
	],
	"Archumen 09-10 Western Interface": [
		"arch09west_winners.jpg",
		"arch09west_2runrup.jpg",
		"arch09west_1runrup.jpg"
	],
	"Archumen 09-10 Southern Interface": [
		"southern_progress101.jpg",
		"southern_progress102.jpg",
		"south_finalist103.jpg",
		"south_finalist102.jpg",
		"south_finalist101.jpg",
		"south_102ndrunrup.jpg",
		"south_10archspeaking.jpg",
		"south_10winners.jpg",
		"south_101strunrup.jpg",
	],
	"Archumen 09-10 Northern Interface": [
		"arch09north_1runrup.jpg",
		"arch09north_2runrup.jpg",
		"arch09north_winners_noshow.jpg"
	],
	"Archumen 09-10 Grand Finale": [
		"grandfinale_progress101.jpg",
		"grandfinale_progress102.jpg",
		"grandfinale_progress103.jpg",
		"grandfinale_progress104.jpg",
		"grandfinale_progress105.jpg",
		"grandfinale_progress106.jpg",
		"grandfinale_progress107.jpg",
		"grandfinale_progress108.jpg",
		"grandfinale_progress109.jpg",
		"nationalfinalists_101.jpg",
		"nationalfinalists_102.jpg",
		"nationalfinalists_103.jpg",
		"nationalfinalists_104.jpg",
		"ten_grandfinale2ndarch.jpg",
		"ten_grandfinalewinnersarch.jpg",
		"ten_grandfinale1starch.jpg",
		"ten_grandfinalewinnersall.jpg",
	],
	"Archumen 08-09 Northern Interface": [
		"arch08north_2runrup.jpg",
		"arch08east_winners.jpg",
		"arch08north_1runrup.jpg",
		"arch08east_1runrup.jpg",
		"arch08north_winners.jpg"
	],
	"Archumen 08-09 Eastern Interface": [
		"arch08east_1runrup.jpg",
		"arch08east_winners.jpg"
	],
	"Archumen 08-09 Western Interface": [
		"arch08east_1runrup.jpg",
		"arch08east_winners.jpg"
	],
	"Archumen 08-09 Grand Finale": [
		"arch08_champions.jpg",
		"arch08_winnersgroup.jpg",
		"arch08_audience.jpg"
	],
	"Archumen 07-08": [
		"arch07north_winners.jpg",
		"arch07north_2runrup.jpg",
		"arch07north_1runrup.jpg"
	],
	"Archumen 07-08 West Interface": [
		"arch07west_2runrup.jpg",
		"arch07west_1runrup.jpg",
		"arch07west_winners.jpg",
	],
	"Archumen 07-08 Eastern Interface": [
		"arch07east_winners.jpg"
	],
	"Archumen 07-08 South Interface": [	
		"arch07south_2runrup.jpg",
		"arch07south_winrs.jpg",
		"arch07south_1runrup.jpg",
	],
	"Archumen 07-08 Grand Finale": [
		"arch07_groupall.jpg",
		"arch07finale_2runrup.jpg",
		"arch07finale_winrs.jpg",
		"arch07finale_1runrup.jpg"
	],
	"Archumen 06-07 Northern Interface": [
		"arch06north_winrs.jpg"
	],
	"Archumen 06-07 Western Interface": [
		"arch06west_rnrsup.jpg",
		"arch06west_winers.jpg"
	],
	"Archumen 06-07 Southern Interface": [
		"arch06south_winners.jpg",
		"arch06south_rnrsup.jpg"
	],
	"Archumen 06-07 Eastern Interface": [
		"arch06finale_rnrsup.jpg",
		"arch06finale_winners.jpg"
	],
	"Archumen 05-06 Northern Interface": [
		"arch05north_winners.jpg"
	],
	"Archumen 05-06 Western Interface": [
		"arch05west_runnersup.jpg",
		"arch05west_winners1.jpg"
	],
	"Archumen 05-06 Grand Finale": [
		"arch05finale_1runerup.jpg",
		"arch05finale_2runrsup.jpg"
	],
	"Archumen 04-05 Northern Interface": [
		"arch04north_1runrsup.jpg"
	],
	"Archumen 04-05 Southern Interface": [
		"arch04south_winer.jpg",
		"arch04south_1runrup.jpg"
	],
	"Archumen 04-05 Western Interface": [
		"arch04west_winr.jpg"
	],
	"Archumen 04-05 Eastern Interface": [
		"arch04east_winners.jpg"
	],
	"Archumen 04-05 Grand Finale": [
		"arch04finale_2runrsup.jpg"
	],
	"Archumen 03-04 Northern Interface": [
		"arch03north_winr.jpg",
		"arch03north_runrup.jpg"
	],
	"Archumen 03-04 Southern Interface": [
		"arch03south_winr.jpg",
		"arch03south_runrup.jpg"
	],
	"Archumen 03-04 Western Interface": [
		"arch03west_winr.jpg",
		"arch03west_runrup.jpg"
	],
	"Archumen 03-04 Eastern Interface": [
		"arch03east_winr.jpg",
		"arch03east_runrup.jpg"
	],
	"Archumen 03-04 Grand Finale": [
		"arch03finale_winrs.jpg",
		"arch03finale_2runrup.jpg",
		"arch03finale_1runrup.jpg"
	],
	"Bending Moment 11-12 Northern Interface": [
		"new6.jpg",
		"new5.jpg",
		"new4.jpg",
		"new3.jpg",
		"new2.jpg",
		"new1.jpg"
	],
	"Bending Moment 11-12 Eastern Interface": [
		"bm1112east_2runrup.gif",
		"bm1112east_1runrup.gif",
		"bm1112east_winner.gif"
	],
	"Bending Moment 11-12 Western Interface": [
		"bm1112west_winner.gif",
		"bm1112west_1runrup.gif",
		"bm1112west_2runrup.gif"
	],
	"Bending Moment 11-12 Southern Interface": [
		"bm1112south_winner.gif",
		"bm1112south_1strunrup.gif",
		"bm1112south_2runrup.gif"
	],
	"Bending Moment 11-12 Grand Finale": [
		"bm1112grandfinale_champions.gif",
		"bm1112grandfinale_championstrophy.gif",
		"bm1112grandfinale_champions.gif",
		"bm1112grandfinale_2runrup.gif",
		"bm1112grandfinale_1strunrup.gif",
	],
	"Bending Moment 10-11 Northern Interface": [
		"bm10-north-winners.jpg",
		"bm10-north-2nd.jpg",
		"bm10-north-1st.jpg"
	],
	"Bending Moment 10-11 Eastern Interface": [
		"bm10-east-2nd.jpg",
		"bm10-east-1stup.jpg"
	],
	"Bending Moment 10-11 Grand Finale": [
		"bm_grand1011_gf1.jpg",
		"bm_grand1011_gf2.jpg",
		"bm_grand1011_gf3.jpg",
		"bm_grand1011_nch.jpg"
	],
	"Bending Moment 09-10 Northern Interface": [
		"bm09north_winners.jpg",
		"bm09north_2runrup.jpg",
		"bm09north_1runrup.jpg"
	],
	"Bending Moment 09-10 Western Interface": [
		"bm09west_winners.jpg",
		"bm09west_2runrup.jpg",
		"bm09west_1runrup.jpg",
	],
	"Bending Moment 09-10 Grand Finale": [
		"bm09finale_winnersall.jpg",
		"bm09finale_2nd_runrup.jpg",
		"bm09finale_1st_runrup.jpg",
		"bm09finale_winners.jpg"
	],
	"Bending Moment 08-09 Northern Interface": [
		"bm08north_winners.jpg",
		"bm08north_2runrup.jpg",
		"bm08north1runrup.jpg",
	],
	"Bending Moment 08-09 Eastern Interface": [
		"bm08east_winners.jpg",
		"bm08east_1runrup.jpg",
		"bm08east_2runrup.jpg"
	],
	"Bending Moment 08-09 Western Interface": [
		"bm081.jpg",
		"bm082.jpg"
	],
	"Bending Moment 08-09 Grand Finale": [
		"bm08_finalewinners.jpg",
		"bm08_champions.jpg",
		"bm08_1runrup.jpg",
		"bm08_2runrup.jpg"
	],
	"Bending Moment 07-08 Northern Interface": [
		"bm07north_1runrup.jpg",
		"bm07north_winners.jpg",
		"bm07north_2runrup.jpg"
	],
	"Bending Moment 07-08 Western Interface": [
		"bm07west_1runrup.jpg",
		"bm07west_2runrup.jpg",
		"bm07west_winners.jpg"
	],
	"Bending Moment 07-08 Southern Interface": [
		"bm07south_1runrsup.jpg",
		"bm07south_winrs.jpg",
	],
	"Bending Moment 07-08 Eastern Interface": [
		"bm07east_winners.jpg"
	],
	"Bending Moment 07-08 Grand Finale": [
		"bm07_groupshot.jpg",
		"bm07finale_2runrsup.jpg",
		"bm07finale_1runrup.jpg",
		"bm07finale_winrs.jpg"
	],
	"Bending Moment 06-07 Western Interface": [
		"bm06west_winners.jpg",
		"bm06west_rnrsup.jpg"
	],
	"Bending Moment 06-07 Eastern Interface": [
		"bm06east_winners.jpg",
		"bm06east_rnrsup.jpg"
	],
	"Bending Moment 06-07 Northern Interface": [
		"bm06north_winners.jpg",
		"bm06north_rnrsup.jpg"
	],
	"Bending Moment 06-07 Grand Finale": [
		"bm06finale_champions.jpg",
		"bm06finale_rnrsup.jpg"
	],
	"Book Launch": [
		"booklaunch1.jpg",
		"booklaunch2.jpg"
	],
	"2011 News Clips": [
		"clip_11_1.gif",
		"clip_11_3.gif",
		"clip_11_2.gif",
		"ai_2.jpg",
		"ai_1.jpg"
	],
	"Transparence 10": [
		"transparence_10_5.jpg",
		"transparence_10_3.jpg",
		"transparence_10_4.jpg",
		"transparence_10_1.jpg",
		"transparence_10_2.jpg",
	]
}
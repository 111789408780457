<template>
  <div class="gallery_page">
    <div class="gallery_head">
      <div :style="{ width: $mq == 'mobile' ? '70%' : '40%' }">
        <h5>Gallery</h5>
        <h1 class="mb24">{{ info.title }}</h1>
        <p>
          {{ info.paragraph }}
        </p>
        <button
          v-if="info.actionLink"
          @click="$bus.redirect(info.actionLink, false, true)"
          class="mt24 black"
        >
          {{ info.actionText }}
        </button>
      </div>
      <div v-if="$mq != 'mobile'" style="width: 60%" class="gallery_right">
        <div
          v-for="p in info.displayPhotos.slice(1, 4)"
          :key="p.id"
          class="gallery_display"
          :style="{
            background: 'url(' + p.url + ') center / contain no-repeat',
          }"
        ></div>
      </div>
    </div>
    <div
      v-if="info.displayPhotos.length > 0"
      class="wfill"
      style="height: 50vh"
      :style="{
        background:
          'url(' + info.displayPhotos[0].url + ') center / cover no-repeat',
      }"
    ></div>
    <div class="mt40">
      <h2 class="center">View Gallery</h2>
      <p class="center mt12">
        Select an event below to browse through the image catalogue
      </p>
    </div>

    <div>
      <div
        v-if="masterEvents.length > 0"
        class="me_chip_parent"
        style="border-bottom: 1px solid #eee"
      >
        <div class="flex wrap" style="flex-wrap: true">
          <div
            v-for="me in masterEvents"
            :key="me.id"
            :class="{ active: activeMasterEventId == me.id }"
            @click="
              activeMasterEventId != me.id
                ? (activeMasterEventId = me.id)
                : (activeMasterEventId = '')
            "
            class="me_chip"
          >
            {{ me.name }}
            <span v-if="galleryCounts[me.id]"
              >({{ galleryCounts[me.id] }})</span
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="nowrap mt12 ml48"
      style="border: 1px solid #ccc; width: 200px; padding: 0 12px"
    >
      <i class="ml8 search icon"></i>
      <input
        placeholder="Search"
        type="text"
        class="search_input"
        v-model="search"
      />
    </div>
    <!-- <div
      v-for="g in galleries"
      :key="g.id"
      class="ui fullscreen modal"
      :id="g.id"
    >
      <div class="header">{{ g.Title }}</div>
      <gallery-widget :media="g.photos" />
      <div class="actions">
        <div @click="closeModal(g.id)" class="ui cancel button">Close</div>
      </div>
    </div> -->
    <div class="gallery_grid" v-if="!loading">
      <div
        v-for="g in searchedGalleries"
        :key="g.id"
        class="pointer gallery"
        @click="showGalleryModal(g.id)"
        :style="{
          background: 'url(' + g.photos[0] + ') center / cover no-repeat',
        }"
      >
        <div
          class="mtauto p16"
          style="background: white; border-top: 1px solid #eee"
        >
          <h6>{{ g.Title }}</h6>
          <p class="mt4">{{ g.photos.length }} images</p>
        </div>
        <!-- <vue-gallery-slideshow
          v-if="activeGallery == g.id && g.photos.length"
          @click="
            ev => {
              ev.stopPropagation();
            }
          "
          :images="g.photos.slice(0, g.photos.length)"
          :index="0"
        ></vue-gallery-slideshow> -->
      </div>
      <div>
        <LightBox
          v-if="activeGalleryObj"
          ref="lightbox"
          :media="
            activeGalleryObj.photos
              .slice(0, activeGalleryObj.photos.length)
              .map((p, ix) => {
                console.log(activeGalleryObj.descriptions[ix]);
                return {
                  thumb: p,
                  src: p,
                  caption:
                    activeGalleryObj.descriptions[ix] || activeGalleryObj.Title,
                  index: ix,
                };
              })
          "
          :controlsHidden="false"
          :showCaption="true"
          :onClosed="() => (activeGallery = null)"
        >
          <template v-slot:customCaption="slotProps">
            {{ slotProps.currentMedia.caption }}<br />
          </template>
          <template v-slot:previous>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </template>
          <template v-slot:next>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </template>
          <template v-slot:close>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </template>
        </LightBox>
      </div>
    </div>
    <div v-else class="my-8 flex wfill">
      <h6 style="text-align: center" class="wfill mx-auto italic">
        Loading...
      </h6>
    </div>
  </div>
</template>

<style>
.gallery_display {
  width: 40vh;
  height: 40vh;
  border-radius: 4px;
  position: absolute;
}
.gallery_display:first-child {
  right: 10vw;
  top: -4vw;
}
.gallery_display:nth-child(2) {
  right: 25vw;
  top: 10vh;
}
.gallery_display:last-child {
  right: 30vw;
  top: 15vh;
}
.gallery_head {
  padding-top: 80px;
  margin-left: 6%;
  width: 100%;
  height: 70vh;
  display: flex;
}
.gallery_right {
  width: 50%;
  position: relative;
}
.gallery_grid {
  display: flex;
  padding: 20px 40px;
  border-radius: 12px;
  flex-wrap: wrap;
}
.gallery {
  width: 220px;
  height: 220px;
  background: #eee;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
@media (max-width: 600px) {
  .gallery {
    width: 100%;
  }
}
.gallery h6 {
  font-size: 14px !important;
}
.gallery p {
  font-size: 12px !important;
}
.gallery_page .ui.modal {
  position: fixed;
  top: 60px;
}
</style>

<script>
import qs from "qs";
// import galleryWidget from "../components/gallery-widget";
import { galleryKey } from "../utils/constants";
import LightBox from "vue-it-bigger";
export default {
  name: "gallery",
  components: {
    LightBox,
  },
  data() {
    return {
      loading: false,
      search: "",
      activeGallery: null,
      galleries: [],
      info: { displayPhotos: [], indexedPhotoUrls: [] },
      masterEvents: [],
      activeMasterEventId: "",
      galleryKey: galleryKey,
      console: {
        log: console.log,
      },
    };
  },
  mounted() {
    this.$req
      .get("gallery")
      .then((r) => {
        this.info = r.data;
      })
      .catch((e) => console.log(e));
    this.$req
      .get("master-events")
      .then((r) => {
        this.masterEvents = r.data;
      })
      .catch((e) => console.log(e));
    // this.getGalleries();
  },
  computed: {
    masterEventsKey: function () {
      return this.masterEvents.reduce((acc, obj) => {
        acc[obj.name] = obj.id;
        return acc;
      }, {});
    },
    activeGalleryObj: function () {
      return this.searchedGalleries.find((g) => g.id == this.activeGallery);
    },
    searchedGalleries: function () {
      let galleries = [...this.combinedGalleries];

      // Sort by reverse year
      galleries = galleries.sort((a, b) => {
        let ayear = a.year || 0;
        let byear = b.year || 0;

        return byear - ayear;
      });
      if (this.search) {
        galleries = this.galleries?.filter((g) =>
          g.Title?.toLowerCase()?.includes(this.search?.toLowerCase())
        );
      }
      if (this.activeMasterEventId) {
        galleries = galleries?.filter(
          (g) => g?.master_event == this.activeMasterEventId
        );
      }
      return galleries;
    },
    galleryCounts: function () {
      return this.combinedGalleries.reduce((acc, g) => {
        if (!g || !g.master_event) return acc;
        if (g.master_event) {
          acc[g.master_event] = acc[g.master_event] || 0;
          acc[g.master_event] += 1;
        }
        return acc;
      }, {});
    },
    externalGalleries: function () {
      try {
        const results = Object.entries(galleryKey).map(([name, links]) => {
          // Check if the name has a suffix with [master_event/year]
          // if it does use that as master_event and year
          let master_event = "";
          let year = "";
          if (name.includes("[")) {
            const [nm, suffix] = name.split("[");
            [master_event, year] = suffix.split("/");
            name = nm;
          } else {
            for (let [nm, id] of Object.entries(this.masterEventsKey)) {
              if (name.includes(nm)) {
                master_event = id;
                break;
              }
            }
          }

          return {
            id: name,
            Title: name,
            master_event,
            year,
            photos: [
              ...links.map(
                (l) =>
                  "https://ethosstrapibucket.s3.ap-south-1.amazonaws.com/images/" +
                  l
              ),
              "",
              "",
              "",
            ],
          };
        });
        return results;
      } catch (e) {
        return [];
      }
    },
    combinedGalleries: function () {
      // const ext_galleries = this.externalGalleries.map(g => {
      //   g.photos = [...g.photos, "", "", ""]
      //   return g
      // })
      return [
        ...(this.info.indexedPhotoUrls.map((g) => {
          g.id = Math.floor(Math.random() * 1000000);
          // Check if the name contains "[<master_event>/<year>]"
          // If so, remove it from the name and add the year and
          // master_event to the gallery object
          if (g.Title) {
            const matches = g.Title.match(/\[(.*?)\]/);
            if (matches) {
              const [masterEvent, year] = matches[1].split("/");
              g.Title = g.Title.replace(matches[0], "").trim();
              g.master_event = this.masterEventsKey[masterEvent];
              g.year = parseInt(year);
            }
          }
          return g;
        }) || []),
        // ...(this.externalGalleries || [])
      ];
    },
  },
  methods: {
    closeModal: function (id) {
      /* eslint-disable */
      $(`#${id}`).hide();
    },
    showGalleryModal: function (id) {
      /* eslint-disable */
      // $(`#${id}`).show();
      this.activeGallery = id;
      if (this.$refs.lightbox) {
        this.$refs.lightbox.showImage(0);
      }
    },
    getGalleries: function () {
      let filter = {
        _where: {
          googlePhotosUrl_ne: "",
        },
        _limit: -1,
      };
      let query = qs.stringify(filter);
      this.loading = true;
      // this.$req
      //   .get(`events?${query}`)
      //   .then(r => {
      //     // this.galleries = r.data
      //     this.$req
      //       .post("getPhotosFromAlbums", {
      //         albums: r.data
      //       })
      //       .then(res => {
      //         const ext_galleries = this.externalGalleries.map(g => {
      //           g.photos = [...g.photos, "", "", ""];
      //           return g;
      //         });
      //         this.galleries = [...this.externalGalleries, ...res.data];
      //         this.loading = false;
      //       })
      //       .catch(e => console.log(e));
      //   })
      //   .catch(e => console.log(e));
    },
  },
};
</script>

<style>
.vib-open {
  overflow: hidden;
}

.no-scroll {
  overflow-y: hidden;
}

.vib-hideable {
  transition: opacity 0.5s ease;
}

.vib-hidden {
  opacity: 0;
}

/* elements styling */

.vib-container {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  box-sizing: border-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 2000;
  -webkit-align-items: center;
  -moz-box-sizing: border-box;
  -webkit-justify-content: center;
  -ms-flex-align: center;
  -webkit-box-align: center;
  -ms-flex-pack: center;
  -webkit-box-pack: center;
}

.vib-content {
  position: relative;
  margin: 0;
  display: block;
}

.vib-image {
  cursor: pointer;
  max-height: calc(100vh);
  cursor: pointer;
  display: block;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.vib-thumbnail-wrapper {
  background: rgba(0, 0, 0, 0.2);
  padding: 10px 12px;
  text-align: center;
  white-space: nowrap;
  position: fixed;
  display: inline-block;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  top: 0px;
}

.vib-thumbnail {
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.2);
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px 2px 0 2px;
  overflow: hidden;
  width: 50px;
}

.vib-thumbnail-active {
  background-position: center;
  background-size: cover;
  border-radius: 4px;
  box-shadow: inset 0 0 0 2px white;
  cursor: pointer;
  display: inline-block;
  height: 50px;
  margin: 2px 2px 0 2px;
  overflow: hidden;
  width: 50px;
}

.vib-footer {
  box-sizing: border-box;
  color: white;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  line-height: 1.3;
  padding: 10px;
  justify-content: space-between;
  text-align: left;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.vib-footer-count {
  flex-shrink: 0;
  padding-left: 1em;
}

.vib-close {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  outline: none;
  position: fixed;
  top: 0;
  right: 0;
  vertical-align: bottom;
  height: 65px;
  width: 60px;
  padding: 15px 12px 15px 20px;
  border-bottom-left-radius: 50%;
}

.vib-arrow {
  background: rgba(0, 0, 0, 0.2);
  border: none;
  cursor: pointer;
  outline: none;
  padding: 10px;
  position: absolute;
  top: 50%;
  user-select: none;
  height: 65px;
  margin-top: -60px;
  width: 60px;
}

.vib-arrow-left {
  left: 0;
  padding-right: 20px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}

.vib-arrow-right {
  right: 0;
  padding-left: 20px;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

@media (min-width: 500px) {
  .vib-thumbnail-arrow {
    width: 40px;
  }
}

@media (min-width: 768px) {
  .vib-arrow {
    width: 60px;
  }
}

/* image transitions */

.vib-image-transition-enter-active,
.vib-image-transition-leave-active {
  transition: opacity 0.2s ease;
}

.vib-image-transition-enter,
.vib-image-transition-leave-to {
  opacity: 0;
}

.vib-image-no-transition-enter-active,
.vib-image-no-transition-leave-active {
  transition: none;
}

.vib-image-no-transition-enter,
.vib-image-no-transition-leave-to {
  opacity: 0;
}

/* container transitions */

.vib-container-transition-enter-active,
.vib-container-transition-leave-active {
  transition: opacity 0.3s ease;
}

.vib-container-transition-enter,
.vib-container-transition-leave-to {
  opacity: 0;
}

.video-background {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  width: calc(90vw);
  height: calc(90vh);
}

.video-background iframe {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 50.25vw;
  max-height: 100vh;
  max-width: 182.78vh;
}
</style>
